import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   name: 'root',
  //   component: () => import('../views/PromotionView.vue'),
  // },
  {
    path: '/',
    meta: { breadCrumb: 'Home', requiresAuth: true },
    component: () => import('../views/DashboardLayout.vue'),
    children: [
      {
        path: '',
        name: 'landing',
        component: () => import('../views/DashboardLanding.vue'),
      },
      // {
      //   path: 'dashboard',
      //   name: 'dashboard',
      //   meta: { breadCrumb: 'Dashboard', requiresAuth: true },
      //   component: () => import('../views/HomeView.vue'),
      // },
      {
        path: 'schedule',
        name: 'schedule',
        meta: {
          breadCrumb: 'Schedule',
          requiresAuth: true,
          namePath: 'schedule',
        },
        component: () => import('../views/ScheduleView.vue'),
      },
      {
        path: 'mps',
        name: 'mps',
        meta: {
          breadCrumb: 'MPS',
          requiresAuth: true,
          namePath: 'mps',
        },
        component: () => import('../views/MpsView.vue'),
      },
      {
        path: 'user',
        name: 'user',
        meta: {
          breadCrumb: 'User',
          requiresAuth: true,
          namePath: 'user',
        },
        component: () => import('../views/UserView.vue'),
      },
      {
        path: 'purchase',
        meta: {
          breadCrumb: 'Purchase Order',
          requiresAuth: true,
          namePath: 'purchase',
        },
        component: () => import('../views/RouteView.vue'),
        children: [
          {
            path: '',
            name: 'purchase',
            component: () => import('../views/PurchaseView.vue'),
          },
          {
            path: ':id',
            name: 'purchase-detail',
            meta: { breadCrumb: 'Purchase Detail', requiresAuth: true },
            component: () => import('../views/PurchaseDetailView.vue'),
          },
        ],
      },
      {
        path: 'monitoring-pchsch',
        name: 'monitoring-pchsch',
        meta: {
          breadCrumb: 'Incoming Monitoring',
          requiresAuth: true,
          namePath: 'monitoring-pchsch',
        },
        component: () => import('../views/PurchaseMonitoringView.vue'),
      },
      {
        path: 'purchase-summary',
        meta: {
          breadCrumb: 'PO Summary',
          requiresAuth: true,
          namePath: 'purchase-summary',
        },
        name: 'purchase-summary',
        component: () => import('../views/PurhcaseSummaryView.vue'),
      },
      {
        path: 'supplier-purchase-summary',
        meta: {
          breadCrumb: 'PO Summary',
          requiresAuth: true,
          namePath: 'supplier-purchase-summary',
        },
        name: 'supplier-purchase-summary',
        component: () => import('../views/SupplierSummaryView.vue'),
      },
      {
        path: 'supplier-purchase-confirmation',
        meta: {
          breadCrumb: 'PO Confirmation',
          requiresAuth: true,
          namePath: 'supplier-purchase-confirmation',
        },
        name: 'supplier-purchase-confirmation',
        component: () => import('../views/SupplierConfirmationView.vue'),
      },
      {
        path: 'supplier-purchase-delivery',
        meta: {
          breadCrumb: 'PO Delivery',
          requiresAuth: true,
          namePath: 'supplier-purchase-delivery',
        },
        component: () => import('../views/RouteView.vue'),
        children: [
          {
            path: '',
            name: 'supplier-purchase-delivery',
            component: () => import('../views/SupplierDoView.vue'),
          },
          {
            path: ':id',
            name: 'supplier-purchase-delivery-detail',
            meta: { breadCrumb: 'PO Delivery Detail', requiresAuth: true },
            component: () => import('../views/SupplierDoDetailView.vue'),
          },
        ],
      },
      {
        path: 'gr-purchase',
        meta: {
          breadCrumb: 'GR purchase',
          requiresAuth: true,
          namePath: 'gr-purchase',
        },
        component: () => import('../views/RouteView.vue'),
        children: [
          {
            path: '',
            name: 'gr-purchase',
            component: () => import('../views/ReceiptView.vue'),
          },
          {
            path: ':id',
            name: 'gr-purchase-detail',
            meta: { breadCrumb: 'GR Purchase Detail', requiresAuth: true },
            component: () => import('../views/ReceiptDetailView.vue'),
          },
        ],
      },
      {
        path: 'gr-others',
        meta: {
          breadCrumb: 'GR Others',
          requiresAuth: true,
          namePath: 'gr-others',
        },
        component: () => import('../views/RouteView.vue'),
        children: [
          {
            path: '',
            name: 'gr-others',
            component: () => import('../views/GoodReceiptView.vue'),
          },
          {
            path: ':id',
            name: 'gr-others-detail',
            meta: { breadCrumb: 'GR Others Detail', requiresAuth: true },
            component: () => import('../views/GoodReceiptDetailView.vue'),
          },
        ],
      },
      {
        path: 'gi-others',
        meta: {
          breadCrumb: 'GI Others',
          requiresAuth: true,
          namePath: 'gi-others',
        },
        component: () => import('../views/RouteView.vue'),
        children: [
          {
            path: '',
            name: 'gi-others',
            component: () => import('../views/GoodIssueView.vue'),
          },
          {
            path: ':id',
            name: 'gi-others-detail',
            meta: { breadCrumb: 'GI Others Detail', requiresAuth: true },
            component: () => import('../views/GoodIssueDetailView.vue'),
          },
        ],
      },
      {
        path: 'output-produksi',
        name: 'output-produksi',
        meta: {
          breadCrumb: 'Production Confirmation',
          requiresAuth: true,
          namePath: 'output-produksi',
        },
        component: () => import('../views/OutputProduksiView.vue'),
      },

      {
        path: 'stock',
        name: 'stock',
        meta: {
          breadCrumb: 'Stock',
          requiresAuth: true,
          namePath: 'stock',
        },
        component: () => import('../views/StockView.vue'),
      },
      {
        path: 'stock-others',
        name: 'stock-others',
        meta: {
          breadCrumb: 'Vendors Stock',
          requiresAuth: true,
          namePath: 'stock-others',
        },
        component: () => import('../views/StockOthersView.vue'),
      },
      {
        path: 'master-item',
        name: 'master-item',
        meta: {
          breadCrumb: 'Master Item',
          requiresAuth: true,
          namePath: 'master-item',
        },
        component: () => import('../views/MasterItemView.vue'),
      },
      {
        path: 'master-bom',
        name: 'master-bom',
        meta: {
          breadCrumb: 'Master BOM',
          requiresAuth: true,
          namePath: 'master-bom',
        },
        component: () => import('../views/MasterBomView.vue'),
      },
      {
        path: 'master-customer',
        name: 'master-customer',
        meta: {
          breadCrumb: 'Master Customer',
          requiresAuth: true,
          namePath: 'master-customer',
        },
        component: () => import('../views/MasterCustomerView.vue'),
      },
      {
        path: 'master-vendor',
        name: 'master-vendor',
        meta: {
          breadCrumb: 'Master Vendor',
          requiresAuth: true,
          namePath: 'master-vendor',
        },
        component: () => import('../views/MasterVendorView.vue'),
      },
      {
        path: 'history-inventory',
        name: 'history-inventory',
        meta: {
          breadCrumb: 'History Inventory',
          requiresAuth: true,
          namePath: 'history-inventory',
        },
        component: () => import('../views/HistoryView.vue'),
      },
      {
        path: 'change-password',
        name: 'change-password',
        meta: {
          breadCrumb: 'Change Password',
          requiresAuth: true,
          namePath: 'change-password',
        },
        component: () => import('../views/ChangePasswordView.vue'),
      },
      {
        path: 'delivery',
        meta: {
          breadCrumb: 'Delivery',
          requiresAuth: true,
          namePath: 'delivery',
        },
        component: () => import('../views/RouteView.vue'),
        children: [
          {
            path: '',
            name: 'delivery',
            component: () => import('../views/SalesView.vue'),
          },
          {
            path: ':id',
            name: 'delivery-detail',
            meta: { breadCrumb: 'Delivery Detail', requiresAuth: true },
            component: () => import('../views/SalesDetailView.vue'),
          },
        ],
      },
      {
        path: 'adjustment',
        meta: {
          breadCrumb: 'Inventory Adjustment',
          requiresAuth: true,
          namePath: 'adjustment',
        },
        component: () => import('../views/RouteView.vue'),
        children: [
          {
            path: '',
            name: 'adjustment',
            component: () => import('../views/AdjustmentView.vue'),
          },
          {
            path: ':id',
            name: 'adjustment-detail',
            meta: { breadCrumb: 'adjustment Detail', requiresAuth: true },
            component: () => import('../views/AdjustmentDetailView.vue'),
          },
        ],
      },
      {
        path: 'transfer',
        name: 'transfer',
        meta: {
          breadCrumb: 'Transfer',
          requiresAuth: true,
          namePath: 'transfer',
        },
        component: () => import('../views/TransferView.vue'),
      },
      {
        path: 'statistic',
        name: 'statistic',
        meta: {
          breadCrumb: 'Statistic',
          requiresAuth: true,
          namePath: 'statistic',
        },
        component: () => import('../views/StatisticView.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginUser.vue'),
  },
  {
    path: '/*',
    component: () => import('../views/404Page.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
  next();
});

export default router;
