import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user: null,
    token: null,
    role: null,
  },
  getters: {
    isAuthenticated: (state) => !!state.user,
    loginToken: (state) => state.token,
    loginUser: (state) => state.user,
    loginRole: (state) => state.role,
  },
  mutations: {
    LogOut(state) {
      state.user = null;
      state.token = null;
      state.role = null;
    },
    setLogin(state, data) {
      state.user = data.username || null;
      state.token = data.token || null;
      state.role = data.role || null;
    },
  },
  actions: {
    async LogIn({ commit }, User) {
      await commit('setLogin', {
        username: User.username,
        token: User.token,
        role: User.role,
      });
    },
    async LogOut({ commit }) {
      await commit('LogOut');
    },
  },
  modules: {},
});
