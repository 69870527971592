import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';

import DrVueEcharts from 'dr-vue-echarts';

Vue.use(DrVueEcharts);

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// import '@progress/kendo-theme-fluent/dist/all.css';
import '@progress/kendo-theme-default/dist/all.css';
// import '@progress/kendo-theme-bootstrap/dist/all.css';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

axios.interceptors.request.use((config) => {
  const token = store.getters.loginToken;
  config.headers['Authorization'] = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const vm = new Vue();
    console.log(error);
    if (!error.response) {
      vm.$bvToast.toast(
        'Server is not responding, please check your internet connection!',
        {
          title: `Server is not responding!`,
          variant: 'danger',
          solid: true,
        }
      );
    } else if (error.response.status === 401) {
      vm.$bvToast.toast(
        error?.response?.data?.response_message ||
          'User token login was expired, please reloggin to verify user!',
        {
          title: `Access Unauthorized!`,
          variant: 'danger',
          solid: true,
        }
      );
      store.dispatch('LogOut');
      router.push({ name: 'login' });
    } else {
      console.log(error.response.data);
      const h = vm.$createElement;
      vm.$bvToast.toast(
        h(
          'span',
          {
            class: ['text-left', 'mb-0'],
            style: { 'white-space': ' pre-wrap' },
          },
          [
            error?.response?.data?.response_message ||
              'Something wrong happen, contact PIC for the Details!',
          ]
        ),
        {
          title: `Process Failed!`,
          variant: 'danger',
          solid: true,
        }
      );
      throw error;
    }
  }
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
